<!-- eslint-disable vue/no-template-shadow -->
<template>
  <content-with-sidebar
    class="cws-container cws-sidebar-right blog-wrapper show-ticket-wrapper"
  >
    <!-- content -->
    <div
      v-if="ticket"
      class="blog-detail-wrapper"
    >
      <b-row>
        <b-col cols="12">
          <b-card
            img-top
            title="Ticket Subject"
          >
            <div
              v-if="user.isAdmin"
              style="display:flex; gap:35px;"
            >
              <div style="width: 100%;">
                <b-form-input
                  v-model="ticket.ticket_subject"
                  style="margin-bottom: 25px; font-weight: bolder;"
                />
              </div>
              <div>
                <p
                  v-if="initialTitle !== ticket.ticket_subject"
                  class="btn btn-primary"
                  @click="editTitle"
                >
                  Update Title
                </p>
              </div>
            </div>
            <div v-else>
              <h3 style="margin-bottom: 15px;">
                {{ ticket.ticket_subject }}</h3>
            </div>
            <b-media no-body>
              <b-media-aside
                vertical-align="center"
                class="mr-50"
              >
                <b-avatar
                  size="24"
                  :src="ticket.author_avatar"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted mr-50">by</small>
                <small>
                  <b-link
                    v-if="ticket.author_is_admin"
                    class="text-body"
                  >TrafficManager Support</b-link>
                  <router-link
                    v-else-if="user.userRole === 'Admin'"
                    :to="`/user/details/${ticket.uid}`"
                    class="text-body"
                  >{{ ticket.author_email }} ( {{ ticket.author_name }} )</router-link>
                  <b-link
                    v-else
                    class="text-body"
                  >{{ ticket.author_email }} ( {{ ticket.author_name }} )</b-link>
                </small>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text-muted">{{ getElapseDate(ticket.created_at) }}</small>
                <b-button
                  variant="outline-success"
                  size="sm"
                  class="ml-1"
                  style="padding: 5px;"
                  @click="accessPlatform(ticket.uid)"
                >
                  Access platform
                </b-button>
              </b-media-body>
            </b-media>

            <div
              v-if="user.userRole === 'Admin'"
              class="my-1 py-25"
            >
              <b-link
                v-for="tag in [ticket.ticket_type]"
                :key="tag.code"
                v-b-modal.categories-modal
                v-b-tooltip.hover.top="'Ticket category'"
              >
                <b-badge
                  pill
                  class="mr-75"
                  :variant="typeTagColor(tag.code)"
                >
                  {{ tag.label }}
                </b-badge>
              </b-link>
              <b-link
                v-for="tag in [ticket.ticket_status]"
                :key="tag.code"
                v-b-modal.statuses-modal
                v-b-tooltip.hover.top="'Ticket status'"
              >
                <b-badge
                  pill
                  class="mr-75"
                  :variant="statusTagColor(tag.code)"
                >
                  {{ tag.label }}
                </b-badge>
              </b-link>
            </div>
            <div
              v-else
              class="my-1 py-25"
            >
              <b-link
                v-for="tag in [ticket.ticket_type]"
                :key="tag.code"
                v-b-tooltip.hover.top="'Ticket category'"
              >
                <b-badge
                  pill
                  class="mr-75"
                  :variant="typeTagColor(tag.code)"
                >
                  {{ tag.label }}
                </b-badge>
              </b-link>
              <b-link
                v-for="tag in [ticket.ticket_status]"
                :key="tag.code"
                v-b-tooltip.hover.top="'Ticket status'"
              >
                <b-badge
                  pill
                  class="mr-75"
                  :variant="statusTagColor(tag.code)"
                >
                  {{ tag.label }}
                </b-badge>
              </b-link>
            </div>

            <!-- Change category modal -->
            <b-modal
              id="categories-modal"
              cancel-variant="outline-secondary"
              ok-only
              ok-title="Apply"
              centered
              title="Change category"
              no-close-on-backdrop
              @ok="changeCategory"
            >
              <b-form>
                <b-form-group
                  label="Category:"
                  label-for="categ"
                >
                  <v-select
                    id="categ"
                    v-model="category"
                    :options="categories"
                    :clearable="false"
                  />
                </b-form-group>
              </b-form>
            </b-modal>

            <!-- Change status modal -->
            <b-modal
              id="statuses-modal"
              cancel-variant="outline-secondary"
              ok-only
              ok-title="Apply"
              centered
              title="Change status"
              no-close-on-backdrop
              @ok="changeStatus"
            >
              <b-form>
                <b-form-group
                  label="Status:"
                  label-for="statuses"
                >
                  <v-select
                    id="statuses"
                    v-model="status"
                    :options="statuses"
                    :clearable="false"
                  />
                </b-form-group>
              </b-form>
            </b-modal>
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="ticket.author_is_admin"
              class="blog-content mb-2"
              v-html="'<pre class=\'blog-content-pre\'>'+ticket.ticket_text+'</pre>'"
            />
            <!--eslint-enable-->
            <div
              v-else
              class="blog-content mb-2"
              v-html="'<pre class=\'blog-content-pre\'>'+ticket.ticket_text+'</pre>'"
            />

            <hr class="my-2">

            <div class="d-flex align-items-top justify-content-between">
              <div class="d-flex align-items-top">
                <div class="d-flex align-items-top mr-1">
                  <b-link
                    class="mr-50"
                    @click="scrollToForm"
                  >
                    <feather-icon
                      icon="MessageSquareIcon"
                      size="21"
                    />
                  </b-link>
                  <div class="text-body">
                    {{ ticket.messages.length + ' messages' }}
                  </div>
                </div>
                <div
                  v-if="ticket.ticket_files.length>0"
                  class="d-flex align-items-top mr-1"
                >
                  <ul>
                    <feather-icon
                      icon="PaperclipIcon"
                      size="21"
                    />  See attatchments:
                    <li
                      v-for="(thisFile,index) in ticket.ticket_files"
                      :key="index"
                      style="z-index:99"
                    >
                      <span
                        class="mr-50 cursor-pointer text-primary"
                        @click="downloadFile(thisFile)"
                      >
                        {{ thisFile.split("/")[thisFile.split("/").length - 1].split("-").slice(2).join("-") }}
                      </span>
                    </li>
                  </ul>
                </div>
                <div
                  v-else-if="ticket.ticket_file"
                  class="d-flex align-items-top mr-1"
                >
                  <b-link
                    class="mr-50"
                    :href="ticket.ticket_file"
                    target="_blank"
                  >
                    <feather-icon
                      icon="PaperclipIcon"
                      size="21"
                    /> See attachment
                  </b-link>
                </div>
              </div>
              <b-link
                v-if="user.userRole === 'Admin'"
                class="assignToCls"
                style="height: 25px;"
              >
                <b-badge
                  v-if="assignedTo"
                  v-b-modal.admins-modal
                  variant="secondary"
                >
                  Assigned to: {{ ticket.assignedTo ? ticket.assignedTo.forename : 'Nobody' }}
                </b-badge>
              </b-link>
              <b-button
                v-else-if="ticket.ticket_status.code !== 'closed'"
                variant="primary"
                :disabled="closing"
                size="sm"
                class="buttonCls"
                @click="closeTicket"
              >
                Close ticket
              </b-button>
              <b-button
                v-else
                variant="primary"
                :disabled="closing"
                class="buttonCls"
                size="sm"
                @click="reopenTicket"
              >
                Reopen ticket
              </b-button>
            </div>
            <hr>
            <small
              v-if="user.userRole === 'Admin'"
              style="display:flex; align-items:center !important; justify-content: space-between;"
            >
              <div
                style="display:flex; align-items:center !important; gap:10px;"
              >
                <b-badge
                  v-b-modal.notes-modal
                  variant="primary"
                >
                  Note
                </b-badge>
                <div
                  v-if="note.length > 3"
                  class="alert-warning"
                  role="warning"
                >
                  ({{ note }})
                </div>
              </div>
            </small>
          </b-card>
        </b-col>

        <b-modal
          id="admins-modal"
          cancel-variant="outline-secondary"
          ok-only
          ok-title="Apply"
          centered
          title="Assign ticket"
          no-close-on-backdrop
          @ok="changeAssignedTo"
        >
          <b-form>
            <b-form-group
              label="Assign to:"
              label-for="admin"
            >
              <v-select
                id="admin"
                v-model="assignedTo"
                :options="admins"
                :clearable="false"
                label="forename"
              />
            </b-form-group>
          </b-form>
        </b-modal>
        <!-- NOTES MODAL -->
        <b-modal
          id="notes-modal"
          cancel-variant="outline-secondary"
          ok-only
          ok-title="Apply"
          centered
          title="Note Description"
          no-close-on-backdrop
          @ok="createNote"
        >
          <b-form>
            <b-form-group>
              <b-form-textarea v-model="note" />
            </b-form-group>
          </b-form>
        </b-modal>

        <!-- Messages -->
        <b-col
          id="blogComment"
          cols="12"
          class="mt-2"
        >
          <div v-if="changesHistory.length >= 1">
            <h6 class="section-label">
              History
            </h6>
            <HistoryComponent :history="changesHistory" />
            <br>
          </div>

          <h6 class="section-label">
            Messages
          </h6>
          <b-card
            v-for="(ticketMessage,index) in ticket.messages"
            :key="index"
          >
            <b-media no-body>
              <b-media-aside class="mr-75">
                <b-avatar
                  :src="ticketMessage.author_avatar"
                />
              </b-media-aside>
              <b-media-body>
                <h6 class="font-weight-bolder mb-25">
                  <span
                    v-if="ticketMessage.author_is_admin"
                    style="display: flex; justify-content: space-between; align-items: center;"
                  >
                    <span>
                      TrafficManager Support <span v-if="user.isAdmin && ticketMessage.internal_reply_name">({{ ticketMessage.internal_reply_name }})</span>
                      <feather-icon
                        v-if="user.isAdmin"
                        icon="Edit3Icon"
                        size="16"
                        class="text-success"
                        style="cursor: pointer;"
                        @click="editMessage(index)"
                      />
                      <b-card-text style="font-weight: 400;">{{ ticketMessage.created_at }}</b-card-text>
                    </span>
                    <b-button
                      v-if="user.isAdmin"
                      variant="danger"
                      size="sm"
                      @click="deleteMessage(ticketMessage.id)"
                    >
                      Delete
                    </b-button>
                  </span>
                  <span
                    v-else
                    style="display: flex; justify-content: space-between; align-items: center;"
                  >
                    <span>
                      {{ ticketMessage.author_email }} <small>({{ ticketMessage.author_name }})</small>
                      <b-card-text style="font-weight: 400;">{{ ticketMessage.created_at }}</b-card-text>
                    </span>
                  </span>
                </h6>

                <!-- eslint-disable vue/no-v-html -->
                <b-card-text
                  v-if="ticketMessage.editbutton===false"
                  class="blog-content mb-2"
                  v-html="'<pre class=\'blog-content-pre\'>'+ticketMessage.msg_text+'</pre>'"
                />
                <div v-else>
                  <quill-editor
                    v-model="ticketMessage.msg_text"
                    :options="snowOption"
                    placeholder="Message"
                  />
                  <br>
                  <label>
                    &nbsp; Add an image/file (optional - e.g. screen-shot)
                    <router-link
                      v-b-tooltip.hover.focus.v-primary
                      :to="''"
                      :class="'bi bi-info-circle'"
                      :title="'Accepted formats: zip, txt, html, htm, png, jpg, bmp, ico, gif, xlsx, csv, pdf'"
                    />
                  </label>
                  <validation-observer ref="editRule">
                    <validation-provider
                      v-slot="{ errors }"
                      name="editfile"
                      rules="mimes:zip,text/plain,html,htm,image/png,image/jpeg,image/bmp,image/ico,image/gif,xlsx,csv,pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf,text/csv"
                    >
                      <b-form-file
                        id="editfile"
                        ref="editfile"
                        v-model="ticketMessage.msg_files"
                        accept=".zip,.txt,.html,.htm,.png,.jpg,.bmp,.ico,.gif,.xlsx,.csv,.pdf"
                        :state="errors.length > 0 ? false:null"
                        multiple
                        plain
                        style="display:none"
                        @input="onChange2(ticketMessage)"
                      /><br>
                      <b-button
                        variant="primary"
                        size="sm"
                        @click="browse2"
                      >
                        Browse...
                      </b-button>
                      <br>
                      <small
                        v-if="!(filesAccumulatedEdit.length>0 || ticketMessage.msg_files.length>0)"
                      >&nbsp;No file/s selected.</small>
                      <br>
                      <small class="text-danger">&nbsp;{{ errors[0] }}</small>
                    </validation-provider>
                  </validation-observer>
                  <div
                    v-for="(thisFile,index) in filesAccumulatedEdit"
                    :key="index"
                  >
                    <file-attachment
                      :filename="thisFile.name"
                      :index="index"
                      @removeItemFromArray="removeItemAttachment2"
                    />
                  </div>
                  <br v-if="filesAccumulatedEdit.length===0">
                  <br>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    :disabled="editsending"
                    @click="saveEditMessage(ticketMessage,index)"
                  >
                    Update
                  </b-button>
                </div>
              </b-media-body>
            </b-media>
            <hr
              v-if="ticketMessage.msg_files && ticketMessage.editbutton===false"
              class="my-2"
            >

            <div
              v-if="ticketMessage.msg_files && ticketMessage.editbutton===false"
              class="d-flex align-items-center justify-content-between"
            >
              <div
                v-if="ticketMessage.msg_files.length>0"
                class="d-flex align-items-center"
              >
                <ul>
                  <feather-icon
                    icon="PaperclipIcon"
                    size="21"
                  />  See attatchments:
                  <li
                    v-for="(thisFile,index) in ticketMessage.msg_files"
                    :key="index"
                  >
                    <span
                      v-if="thisFile.length>0"
                      class="mr-50 cursor-pointer text-primary"
                      @click="downloadFile(thisFile)"
                    >
                      {{ thisFile.split("/")[thisFile.split("/").length - 1].split("-").slice(2).join("-") }}
                    </span>
                  </li>
                  <li v-if="ticketMessage.msg_file!==''">
                    <b-link
                      class="mr-50"
                      :href="ticketMessage.msg_file"
                      target="_blank"
                    >
                      Old file
                    </b-link>
                  </li>
                </ul>
              </div>
              <div
                v-if="ticketMessage.msg_file!=='' && ticketMessage.msg_files.length===0"
                class="d-flex align-items-center mr-1"
              >
                <b-link
                  class="mr-50"
                  :href="ticketMessage.msg_file"
                  target="_blank"
                >
                  <feather-icon
                    icon="PaperclipIcon"
                    size="21"
                  /> See attachment
                </b-link>
              </div>
            </div>
          </b-card>
        </b-col>
        <!--/ Messages -->

        <!-- Add a message -->
        <b-col
          cols="12"
          class="mt-2"
        >
          <h6 class="section-label">
            Add a message
          </h6>
          <b-card>
            <validation-observer ref="rules">
              <b-form>
                <b-col cols="12">
                  <b-form-group class="mb-2">
                    <validation-provider
                      #default="{ errors }"
                      mode="passive"
                      name="Message body"
                      rules="required"
                    >
                      <quill-editor
                        v-if="user.userRole === 'Admin'"
                        v-model="message.msg_text"
                        :options="snowOption"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Message"
                      />
                      <b-form-textarea
                        v-else
                        v-model="message.msg_text"
                        name="textarea"
                        rows="4"
                        placeholder="Message"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-row>
                  <b-col cols="4">
                    <b-form-group>
                      <label>
                        Add an image/file (optional - e.g. screen-shot)
                        <router-link
                          v-b-tooltip.hover.focus.v-primary
                          :to="''"
                          :class="'bi bi-info-circle'"
                          :title="'Accepted formats: zip, txt, html, htm, php, png, jpg, bmp, ico, gif, xlsx, csv, pdf'"
                        />
                      </label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="File"
                        rules="mimes:zip,text/plain,html,htm,image/png,image/jpeg,image/bmp,image/ico,image/gif,xlsx,csv,pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf,text/csv"
                      >
                        <b-form-file
                          id="file"
                          ref="file"
                          v-model="message.msg_files"
                          accept=".zip,.txt,.html,.htm,.php,.png,.jpg,.bmp,.ico,.gif,.xlsx,.csv,.pdf"
                          :state="errors.length > 0 ? false:null"
                          multiple
                          plain
                          style="display:none"
                          @input="onChange"
                        /><br>
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="browse"
                        >
                          Browse...
                        </b-button>
                        <br>
                        <small
                          v-if="filesAccumulated.length===0"
                        >&nbsp;No file/s selected.</small>
                        <br>
                        <small class="text-danger">&nbsp;{{ errors[0] }}</small>

                      </validation-provider>
                      <div
                        v-for="(thisFile,index) in filesAccumulated"
                        :key="index"
                      >
                        <file-attachment
                          :filename="thisFile.name"
                          :index="index"
                          @removeItemFromArray="removeItemAttachment"
                        />
                      </div>
                    </b-form-group>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      :disabled="sending"
                      @click="sendMessage"
                    >
                      <span v-if="sending">
                        <b-spinner
                          small
                          type="grow"
                        />
                        Saving on progress, please wait...
                      </span>
                      <span v-else>Add message</span>
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="user.userRole == 'Admin'"
                    cols="8"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          label="Template:"
                          label-for="template"
                        >
                          <b-form-select
                            id="template"
                            v-model="template"
                            @change="changeTemplate"
                          >
                            <b-form-select-option :value="null">
                              Select Template
                            </b-form-select-option>
                            <b-form-select-option
                              v-for="template in responseTemplate"
                              :key="template.id"
                              :value="template.template"
                            >
                              {{ template.title }}
                            </b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="templateForms.length > 0"
                        cols="12"
                        sm="8"
                      >
                        <b-form-group
                          v-for="templateForm in templateForms"
                          :key="templateForm"
                          :label="`${ucwords(templateForm)}:`"
                          :label-for="templateForm"
                        >
                          <b-form-input
                            :id="templateForm"
                            :placeholder="ucwords(templateForm)"
                            type="text"
                            @change="formInput"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
        <!--/ Add a message -->
      </b-row>
    </div>
    <!--/ content -->
  </content-with-sidebar>
</template>

<script>
import {
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BLink,
  BFormGroup,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BForm,
  BFormTextarea,
  BButton,
  BFormFile,
  VBTooltip,
  BSpinner,
  BFormInput,
  BFormSelectOption,
  BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.min'
import axios from '@/libs/axios'
import moment from 'moment'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { quillEditor } from 'vue-quill-editor'
import FileAttachment from './FileAttachment.vue'
import HistoryComponent from './components/HistoryComponent.vue'

export default {
  components: {
    BFormSelect,
    BFormSelectOption,
    BMedia,
    BFormInput,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BBadge,
    BCardText,
    BForm,
    BFormFile,
    BFormTextarea,
    BButton,
    BSpinner,
    ContentWithSidebar,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
    FileAttachment,
    HistoryComponent,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      template: null,
      responseTemplate: [],
      templateForms: [],
      search_query: '',
      commentCheckmark: '',
      ticket: null,
      blogSidebar: {},
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
      message: {
        msg_files: [],
        msg_text: null,
      },
      sending: false,
      editsending: false,
      closing: false,
      category: null,
      categories: [],
      initialTitle: '',
      note: '',
      status: null,
      statuses: [],
      admins: [],
      assignedTo: {
        uid: null,
        forename: 'Nobody',
      },
      filesAccumulated: [],
      filesAccumulatedEdit: [],
      changesHistory: [],
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.userData
    },
  },
  watch: {
    $route(to) {
      this.ticket = null
      this.$store.dispatch('tickets/fetchTicket', to.params.id)
        .then(res => {
          this.ticket = res.data
        })
    },
    // watch for message.msg_text and on change store it in cache
    // eslint-disable-next-line func-names
    'message.msg_text': function (val) {
      if (val) {
        document.cookie = `message.msg_text=${val}; expires=Fri, 31 Dec 9999 23:59:59 GMT`
      }
    },
  },
  created() {
    this.fetchTicket()
    this.fetchChangesHistory()
    this.fetchTicketTemplate()
    if (this.user.userRole === 'Admin') {
      this.$store.dispatch('tickets/fetchTicketTypes').then(() => {
        this.categories = this.$store.state.tickets.ticketTypes
      })
      this.$store.dispatch('tickets/fetchTicketStatuses').then(() => {
        this.statuses = this.$store.state.tickets.ticketStatuses
      })
      this.$store.dispatch('users/fetchAdmins').then(() => {
        this.admins = this.$store.state.users.admins
      })
    }
  },
  methods: {
    async deleteMessage(id) {
      this.confirmationAlert('Are you sure you want to delete this message?').then(async response => {
        if (response.isConfirmed) {
          const res = await axios.post(`/api/tickets/messages/delete_message/${id}`)
          if (res.status === 200) {
            this.fetchTicket()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Message deleted',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else if (res.status !== 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error deleting message',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        }
      })
    },
    async fetchChangesHistory() {
      if (!this.user.isAdmin) return
      const res = await axios.get(`/api/tickets/${this.$route.params.id}/changes/history`)
      if (res.status === 200) {
        this.changesHistory = res.data
      }
    },
    async accessPlatform(uid) {
      const setting = await this.$store.dispatch('setting/fetchSetting', uid)
      if (setting.network_type === 'domain') {
        this.url = `https://${setting.sub_admin}.${setting.domain}`
      } else {
        this.url = `https://${setting.subdomain}.trafficmanager.com`
      }
      window.open(this.url, '_blank')
    },

    async createNote() {
      const payload = {
        note: this.note,
      }
      const res = await axios.patch(`/api/tickets/${this.ticket.id}`, payload)
      if (res.status === 200) {
        // notify
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Note added',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } else if (res.status !== 200) {
        // notify
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    getCookie(cname) {
      const name = `${cname}=`
      const decodedCookie = decodeURIComponent(document.cookie)
      const ca = decodedCookie.split(';')
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          this.message.msg_text = c.substring(name.length, c.length)
          return c.substring(name.length, c.length)
        }
      }
      return ''
    },
    editMessage(index) {
      if (this.ticket.messages[index].editbutton === false) this.ticket.messages[index].editbutton = true
      else if (this.ticket.messages[index].editbutton === true) this.ticket.messages[index].editbutton = false
    },
    saveEditMessage(ticketMessage, index) {
      this.$refs.editRule[0].validate().then(async success => {
        if (success) {
          this.editsending = true

          const formData = new FormData()
          formData.append('id', ticketMessage.id)
          formData.append('msg_text', ticketMessage.msg_text)
          formData.append('uid', this.ticket.uid)

          const i = this.filesAccumulatedEdit.length
          for (let x = 0; x < i; x += 1) {
            formData.append('msg_file[]', this.filesAccumulatedEdit[x])
          }

          const headers = { 'Content-Type': 'multipart/form-data' }
          await axios.post(`/api/messages/update_message/${ticketMessage.id}`, formData, { headers })
            .then(() => {
              this.fetchTicket()
            })
            .catch(error => { console.log(error) })

          this.editsending = false
          this.editMessage(index)
        }
      })
    },
    closeTicket() {
      this.closing = true
      this.status.code = 'closed'
      this.changeStatus().then(() => {
        this.$router.push('/tickets/open-tickets')
      })
    },
    reopenTicket() {
      this.closing = true
      this.status.code = 'open'
      this.changeStatus().then(() => {
        this.$router.push('/tickets/open-tickets')
      })
    },
    scrollToForm() {
      const el = this.$refs.file
      el.$el.scrollIntoView()
    },
    editTitle() {
      this.sending = true
      const formData = new FormData()
      formData.append('ticket_subject', this.ticket.ticket_subject)
      formData.append('uid', this.ticket.uid)

      const headers = { 'Content-Type': 'multipart/form-data' }
      axios.post(`/api/tickets/${this.ticket.id}/messages`, formData, { headers })
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            this.fetchTicket()
            this.sending = false
            // notify the user that the message was sent
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Title updated',
                variant: 'success',
              },
            })
          } else {
            this.sending = false
            // notify the user that the message was not sent
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Error updating the title',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => { console.log(error) })
    },
    sendMessage() {
      let assignedTo = null
      if (this.ticket?.assignedTo?.full_name && this.user.id !== this.ticket.assignedTo.id && this.user.isAdmin) {
        assignedTo = this.ticket.assignedTo.full_name
      }
      if (assignedTo) {
        this.confirmationAlert(`This ticket is assigned to ${assignedTo}. Are you sure you want to send this message?`).then(async response => {
          if (response.isConfirmed) {
            this.$refs.rules.validate().then(success => {
              if (success) {
                this.sending = true
                const formData = new FormData()
                formData.append('msg_text', this.message.msg_text)
                formData.append('uid', this.ticket.uid)
                formData.append('ticket_subject', this.ticket.ticket_subject)

                const i = this.filesAccumulated.length
                for (let x = 0; x < i; x += 1) {
                  formData.append('msg_file[]', this.filesAccumulated[x])
                }

                const headers = { 'Content-Type': 'multipart/form-data' }
                axios.post(`/api/tickets/${this.ticket.id}/messages`, formData, { headers })
                  .then(res => {
                    if (res.status === 200 || res.status === 201) {
                      this.fetchTicket()
                      this.sending = false
                      this.message.msg_text = null
                      this.message.msg_file = null
                      // clean the cookie after sending the message
                      const cleanCookie = ''
                      document.cookie = `message.msg_text=${cleanCookie}`
                    } else {
                      this.sending = false
                      // notify the user that the message was not sent
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Notification',
                          icon: 'BellIcon',
                          text: 'Message not sent',
                          variant: 'danger',
                        },
                      })
                    }
                  })
                  .catch(error => { console.log(error) })
              }
            })
          }
        })
      } else {
        this.$refs.rules.validate().then(success => {
          if (success) {
            this.sending = true
            const formData = new FormData()
            formData.append('msg_text', this.message.msg_text)
            formData.append('uid', this.ticket.uid)
            formData.append('ticket_subject', this.ticket.ticket_subject)

            const i = this.filesAccumulated.length
            for (let x = 0; x < i; x += 1) {
              formData.append('msg_file[]', this.filesAccumulated[x])
            }

            const headers = { 'Content-Type': 'multipart/form-data' }
            axios.post(`/api/tickets/${this.ticket.id}/messages`, formData, { headers })
              .then(res => {
                if (res.status === 200 || res.status === 201) {
                  this.fetchTicket()
                  this.sending = false
                  this.message.msg_text = null
                  this.message.msg_file = null
                  // clean the cookie after sending the message
                  const cleanCookie = ''
                  document.cookie = `message.msg_text=${cleanCookie}`
                } else {
                  this.sending = false
                  // notify the user that the message was not sent
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Notification',
                      icon: 'BellIcon',
                      text: 'Message not sent',
                      variant: 'danger',
                    },
                  })
                }
              })
              .catch(error => { console.log(error) })
          }
        })
      }
    },
    changeCategory() {
      axios.patch(`/api/tickets/${this.ticket.id}`, { ticket_type: this.category.code })
        .then(() => {
          this.ticket.ticket_type = this.category
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: 'Ticket category changed',
              variant: 'success',
            },
          })
        })
        .catch(error => { console.log(error) })
    },
    async changeStatus() {
      await axios.patch(`/api/tickets/${this.ticket.id}`, { ticket_status: this.status.code })
        .then(() => {
          this.ticket.ticket_status = this.status
          this.$store.state.tickets.openTickets = null
          this.$store.state.tickets.closedTickets = null
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: 'Ticket status changed',
              variant: 'success',
            },
          })
        })
        .catch(error => { console.log(error) })
    },
    changeAssignedTo() {
      axios.patch(`/api/tickets/${this.ticket.id}`, { assigned_to: this.assignedTo.uid })
        .then(() => {
          this.ticket.assignedTo = this.assignedTo
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: 'Operation succeeded',
              variant: 'success',
            },
          })
        })
        .catch(error => { console.log(error) })
    },
    downloadFile(file) {
      const fileName = file.split('/')[file.split('/').length - 1]

      if (fileName.includes('.png') || fileName.includes('.jpg') || fileName.includes('.jpeg') || fileName.includes('.pdf')) {
        window.open(file, '_blank')
        return
      }

      axios({
        method: 'post',
        url: '/api/tickets/download-file',
        responseType: 'blob', // Set response type to 'blob' to handle binary data
        data: {
          filename: file.split('/')[file.split('/').length - 1],
        },
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', file.split('/')[file.split('/').length - 1].split('-').slice(2).join('-'))
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link) // Clean up after download
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'An error occurred while downloading the file',
              variant: 'danger',
            },
          })
        })
    },
    typeTagColor(tag) {
      if (tag === 'urgent') return 'light-danger'
      return 'light-primary'
    },
    statusTagColor(tag) {
      if (tag === 'waiting') return 'light-info'
      if (tag === 'onhold') return 'light-secondary'
      if (tag === 'in_progress') return 'light-warning'
      return 'light-primary'
    },
    getElapseDate(Date) {
      return moment(Date).fromNow()
    },
    fetchTicket() {
      this.filesAccumulated = []
      this.filesAccumulatedEdit = []
      this.$store.dispatch('tickets/fetchTicket', this.$route.params.id)
        .then(res => {
          this.ticket = res.data
          this.initialTitle = this.ticket.ticket_subject
          if (this.ticket.note !== null) {
            this.note = this.ticket.note
          }
          this.checkIfAdminMessage()
          this.$store.dispatch('messages/fetchNewMessages')
          this.category = this.ticket.ticket_type
          this.status = this.ticket.ticket_status
          if (this.ticket.assignedTo) {
            this.assignedTo = this.ticket.assignedTo
          }
          this.getCookie('message.msg_text')
        })
    },
    checkIfAdminMessage() {
      const regex = /We are happy to inform you that your/g
      const found = this.ticket.ticket_text.match(regex)
      if (found) {
        this.ticket.ticket_text = `<pre class="blog-content-pre">${this.ticket.ticket_text}</pre>`
        this.ticket.ticket_text = this.ticket.ticket_text.replace("clients\: https", "clients:\<br\>https") // eslint-disable-line
        this.ticket.ticket_text = this.ticket.ticket_text.replace("If you change the password", "<br\>If you change the password") // eslint-disable-line
        this.ticket.ticket_text = this.ticket.ticket_text.replace("will not be able to send emails", "will not be able <br\>to send emails") // eslint-disable-line
      }
    },
    onChange() {
      this.message.msg_files.forEach(thisFile => {
        this.filesAccumulated.push(thisFile)
      })
    },
    onChange2(ticketMessage) {
      ticketMessage.msg_files.forEach(thisFile => {
        this.filesAccumulatedEdit.push(thisFile)
      })
    },
    removeItemAttachment(index) {
      this.filesAccumulated.splice(index, 1)
    },
    removeItemAttachment2(index) {
      this.filesAccumulatedEdit.splice(index, 1)
    },
    browse() {
      this.$refs.file.$el.click()
    },
    browse2() {
      this.$refs.editfile[0].$el.click()
    },
    removeItem() {},
    fetchTicketTemplate() {
      this.$store.dispatch('tickets/fetchTemplates2')
        .then(response => {
          this.responseTemplate = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    changeTemplate() {
      this.message.msg_text = this.template
      const str = this.template
      const pattern = /\{(\w+)\}/g

      const matches = [...str.matchAll(pattern)]
      const forms = matches.map(match => match[1])

      this.templateForms = forms
    },
    formInput() {
      let form2 = this.template
      this.templateForms.forEach(form => {
        if (document.querySelector(`input[id="${form}"]`).value !== '') {
          form2 = form2.replace(`{${form}}`, document.querySelector(`input[id="${form}"]`).value)
        }
      })
      this.message.msg_text = form2
    },
    ucwords(str) {
      return str.replace(/\b\w/g, match => match.toUpperCase())
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
.show-ticket-wrapper .ql-editor {
  min-height: 320px;
}
.blog-content-pre{

 white-space: pre-wrap;       /* css-3 */
 white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
 white-space: -pre-wrap;      /* Opera 4-6 */
 white-space: -o-pre-wrap;    /* Opera 7 */
 word-wrap: break-word;       /* Internet Explorer 5.5+ */
 background-color:white;
 padding:10px;
}
a, p{

  // white-space: pre-wrap;
  // word-wrap: break-word;
  // word-break: break-all;
  // white-space: normal;

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
 }
 .buttonCls{
  height: 40px !important;
 }
 /* iphone 12/13 pro max - android pro max*/
@media only screen and (max-width: 428px)  {
  .media{
    display: inline !important;
  }
  .justify-content-between{
    max-width: 400px !important;
  }
  .text-body{
    width: 100px;
  }
  .assignToCls{
    float:left;
    margin-top: -50px;
    margin-left: -170px;
  }
  .buttonCls{
    float:left;
    margin-top: -60px;
    margin-left: -180px;
    height: 30px !important;
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 415px)  {
  .media{
    display: inline !important;
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 395px)  {
  .media{
    display: inline !important;
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 376px)  {
  .media{
    display: inline !important;
  }
}

/* smaller phone */
@media only screen and (max-width: 365px)  {
  .media{
    display: inline !important;
  }
}
</style>

<style>
textarea.form-control {
  min-height: 100px;
}
img {
  max-width: 100%;
}
</style>
